/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import theme from "../../../../Themes/defaultTheme";
import A_FormHelper from "../../DataDisplay/Tooltips/A_FormHelper";
import { asyncCheckbox } from "../../../Molecules/Common/Renderers/M_FormRendererHelper";

const A_TextField = (props) => {
  let formVlalueType = props.inputType === "value" ? "value" : "defaultValue";
  let inputLabelProps =
    props && props.id === asyncCheckbox.Rationale
      ? {
          style: {
            display: "-webkit-box",
            width: "50ch",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            bottom: 19,
          },
        }
      : {};

  return (
    <ThemeProvider theme={theme}>
      <TextField
        //properties
        name={props.name}
        id={props.id}
        label={props.label}
        variant={props.variant}
        color={props.color}
        size={props.size}
        type={props.type}
        required={props.required}
        disabled={props.disabled}
        InputLabelProps={inputLabelProps}
        InputProps={{
          min: "0",
          max: "10",
          className: props.className,
          readOnly: props.readOnly,
          endAdornment: (
            <A_FormHelper fieldHelperText={props.fieldHelperText} />
          ),
          startAdornment:
            props.adornment !== undefined ? (
              <InputAdornment position={props.adornment.position}>
                {props.adornment.value}
              </InputAdornment>
            ) : (
              ""
            ),
          autoFocus: props.focus,
          [formVlalueType]: props.defaultValue,
        }}
        helperText={props.helperText}
        error={props.error}
        fullWidth={props.fullWidth}
        margin={props.margin}
        multiline={props.multiline}
        rows={props.rows}
        //borderColor={props.borderColor}
        rowsMax={props.rowsMax}
        className={props.className}
        //events
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onKeyPress={props.onKeyPress}
        onKeyDown={(e) => e.stopPropagation()}
        //focus={props.focus}
        onInput={props.onInput}
      ></TextField>
    </ThemeProvider>
  );
};
A_TextField.defaultProps = {
  //id: "123",
  label: "Text field",
  variant: "outlined",
  color: "primary",
  size: "small",
  borderColor: "white",
  required: false,
  disabled: false,
  readOnly: false,
  error: false,
  fullWidth: false,
  margin: "dense",
  multiline: false,
  rows: 0,
  rowsMax: 5,
  helperText: "",
  defaultValue: "",
  inputType: "",
  //focus: false,
  //adornment: {},
};
A_TextField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  adornment: PropTypes.object,
  variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
  color: PropTypes.oneOf(["primary", "secondary"]),
  size: PropTypes.oneOf(["small", "medium"]),
  type: PropTypes.oneOf(["password", "search", "number"]),
  margin: PropTypes.oneOf(["none", "normal", "dense"]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.node,
  defaultValue: PropTypes.node,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  className: PropTypes.any,
};
export default A_TextField;
